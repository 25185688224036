

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { GroupError } from './types'

@Component
export default class AddToGroupsErrors extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) readonly generalErrors!: string[]

  @Prop({
    type: Array,
    default: () => [],
  }) readonly specificErrors!: GroupError[]
}
